export const projects = [
  {
    title: "Restaurant Chez Toi",
    subtitle: "React.js / Node/Express.js",
    description:
      "Restaurant menu management application",
    image: "./Mockup_ChezToi02.jpg",
    link: "https://github.com/leodecorte/projet-pro",
  },
  {
    title: "My Show Time",
    subtitle: "Vue.JS / Nest.JS and MongoDB",
    description:
      "A booking site for concert/festival tickets",
    image: "./Mockup_Showtime02.jpg",
    link: "https://github.com/leodecorte/showtime",
  },
  {
    title: "Dashboard",
    subtitle: "React.JS - API - Micro Service",
    description:
      "Project that implement API Widgets",
    image: "./dashboard.png",
    link: "https://github.com/leodecorte/dashboard",
  },
  {
    title: "Rotten Tomatoes Clone",
    subtitle: "NuxtJS - The Movie Database API",
    description:
      "A Film presentation and review website",
    image: "./RT_Card.jpg",
    link: "https://github.com/leodecorte/",
  },
  {
    title: "My Shop",
    subtitle: "HTML/CSS - PHP",
    description:
      "E-commerce project in HTML/CSS and PHP/MySQL",
    image: "./Mockup_MyShop02.jpg",
    link: "https://github.com/leodecorte/my-shop",
  },
  {
    title: "FreeAds - Epitoken",
    subtitle: "Laravel Framework",
    description:
      "Free-publishing ads website",
    image: "./Epitoken.png",
    link: "https://github.com/leodecorte/my-shop",
  },
];

// export const testimonials = [
//   {
//     quote:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//     image: "https://randomuser.me/api/portraits/men/1.jpg",
//     name: "Jesse Hicks",
//     company: "Zoozle",
//   },
//   {
//     quote:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//     image: "https://randomuser.me/api/portraits/men/94.jpg",
//     name: "Ruben Alvarez",
//     company: "Dooble.io",
//   },
// ];

export const skills = [
  "JavaScript",
  "Vue",
  "React",
  "Nest.js",
  "Node.js / Express.js",
  "PHP",
  "Laravel",
  "REST API",
];
